


















import Vue from 'vue';

// components
import DesktopAppContainer from '@/container/common/DesktopAppContainer.vue';
import JournalContainer from '@/container/journal/JournalContainer.vue';
import JournalChannelsContainer from '@/container/journal/JournalChannelsContainer.vue';

import MobileAppContainer from '@/container/common/MobileAppContainer.vue';
import MobileJournalContainer from '@/container/journal/mobile/MobileJournalContainer.vue';

// service
import { isMobile } from '@/service/StyleService';

export default Vue.extend({
  name: 'JournalScene',

  components: {
    DesktopAppContainer,
    JournalContainer,
    JournalChannelsContainer,
    MobileAppContainer,
    MobileJournalContainer,
  },

  computed: {
    isMobile() {
      return isMobile(window);
    },
  },

});
